<template>
  <v-card
    :disabled="item.locked"
  >
    <v-form
      ref="form"
    >
      <VTitleStichprobe
              :id="item.id"
              :text="item.text"
              :zustaendig="item.zustaendig"
              :vorschrift="item.vorschrift.nameShort"
              :status="item.status"
        :item="item"
      />
      <v-card-text
        class="text-body-1 black--text pt-0"
      >
        <p v-html="statement"></p>
        <v-subheader class="pl-0" style="height:20px">W&auml;hlen Sie die auf das Grundst&uuml;ck zutreffenden Aussagen aus.</v-subheader>
        <v-checkbox
          v-for="(test, i) in item.tests"
          v-model="selected"
          :class="i === 0 ? 'mt-2' : undefined"
          :disabled="item.locked"
          hide-details
          :key="test.id"
          :value="test.id"
        >
          <template #label>
            <span class="black--text">{{ test.text }}</span>
          </template>
        </v-checkbox>
      </v-card-text>

      <v-card-actions>
        <VBtnResponsive
          color="primary"
          depressed
          :disabled="loading || item.locked"
          :loading="loading"
          @click="onSubmit"
        >
          Speichern
        </VBtnResponsive>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import VBtnResponsive from '../../../../components/VBtnResponsive';
import VTitleStichprobe from '../../../../components/VTitleStichprobe';
import _throttle from 'lodash/throttle';
import {StichprobeRepository} from '../../../../repositories/stichprobe-repository';

export default {
  components: {
    VBtnResponsive,
    VTitleStichprobe
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      selected: []
    };
  },
  computed: {
    statement () {
      const besteht = this.item.inverse
        ? 'besteht'
        : '<span class="font-weight-bold">besteht</span>';
      const k_ein = this.item.inverse
        ? '<span class="font-weight-bold">kein</span>'
        : 'ein';
      return `An dem Grundstück ${besteht} ${k_ein} Vorkaufsrecht gemä&szlig; ${this.item.vorschrift.name}.`;
    }
  },
  watch: {
    item: {
      handler (item) {
        this.selected = item.confirmedTests;
      },
      immediate: true
    }
  },
  methods: {
    onSubmit: _throttle(async function () {
      if (this.$refs.form.validate()) {
        await this.patchStichprobe(this.item.id, {
          confirmedTests: this.selected
        });
        this.$store.commit('SET_SNACKBAR_TEXT', `Stichprobe ${this.item.text} gespeichert.`);
        this.$router.push({ name: 'stichprobeAll' });
      }
    }, 450, { trailing: false }),
    async patchStichprobe (id, payload) {
      this.loading = true;
      try {
        const {data} = await StichprobeRepository.patch(id, payload);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
