<template>
  <VLayoutMap
    :drawer.sync="drawer"
    :sidebar.sync="sidebar"
    :sidebar-width="!$vuetify.breakpoint.xs ? 335 : undefined"
  >
    <VMap>
      <VMapView
        :constraints="constraints"
        :extent.sync="extent"
        :scale.sync="scale"
        :ready.sync="viewReady"
        :ui="ui"
        @pointer-move="position = $event"
      >
        <VGraphic
          v-if="graphic"
          v-model="visibleGraphic"
          :properties="graphic"
        />
      </VMapView>
      <template v-if="visibleBasemap">
        <v-basemap
          :id="basemap.id"
        >
          <v-wmts-layer
            :properties="basemap.service"
            :visible-value="true"
          />
        </v-basemap>
      </template>
      <template v-if="viewReady">
        <VMapImageLayer
          v-model="visibleLayers"
          :properties="services.VOKAR"
        />
      </template>
    </VMap>

    <template #top-right>
      <VMapToolbar>
        <VMapToolToggleVisibility
          v-model="visibleGraphic"
          :label="visibleGraphic ? 'Grundstück ausblenden' : 'Grundstück einblenden'"
        />
        <VMapToolZoomToExtent
          v-model="extent"
          :init-extent="initExtent"
          label="Auf Grundstück zoomen"
        />
        <VMapToolCollapseContent
          v-model="sidebar"
          collapse-right
        />
      </VMapToolbar>
    </template>

    <template #bottom-left v-if="stichprobe.id">
      <VMapToolbar class="pr-0">
        <VMapToolCollapseContent
          v-model="drawer"
          collapse-bottom
        />
      </VMapToolbar>
    </template>

    <template #footer v-if="!$vuetify.breakpoint.smAndDown">
      <div
        class="d-flex align-center justify-space-between pa-1"
      >
        <VMapCopyright/>
        <VMapCoordinateViewer
          :position="position"
        />
        <VMapScaleChanger
          v-model="scale"
          :lods="constraints.lods"
        />
      </div>
    </template>

    <template #drawer v-if="stichprobe.id">
      <VVerifyStichprobe
        :item="stichprobe"
      />
    </template>

    <template #sidebar>
      <v-subheader>Hintergrundkarten</v-subheader>
      <v-map-basemap-toggler
        v-model="visibleBasemap"
        :items="[basemap]"
        item-text="title"
      />
      <v-subheader>
        Karteninhalt
      </v-subheader>
      <v-treeview
        v-model="visibleLayers"
        class="pa-2"
        expand-icon="mdi-chevron-down"
        hoverable
        :items="tocItems"
        item-text="title"
        item-children="sublayers"
        open-on-click
        selectable
        selected-color="primary"
      >
        <template v-slot:label="{ item }">
          <div class="d-flex flex-row align-center">
            <v-img
              v-if="item._legend"
              class="mr-2"
              :max-width="20"
              :max-height="20"
              :src="item._legend"
            />
            <span>{{ item.title}}</span>
          </div>
        </template>
      </v-treeview>
    </template>
  </VLayoutMap>
</template>

<script>
import VMapBasemapToggler from '../../components/VMapBasemapToggler';
import VMapCoordinateViewer from '../../components/VMapCoordinateViewer';
import VMapCopyright from '../../components/VMapCopyright';
import VMapScaleChanger from '../../components/VMapScaleChanger';
import VMapToolbar from '../../components/VMapToolbar';
import VMapToolCollapseContent from '../../components/VMapToolCollapseContent';
import VMapToolToggleVisibility from '../../components/VMapToolToggleVisibility';
import VMapToolZoomToExtent from '../../components/VMapToolZoomToExtent';
import VLayoutMap from '../../components/VLayoutMap';
import VVerifyStichprobe from './components/VVerifyStichprobe';
import {StichprobeRepository} from '../../repositories/stichprobe-repository';
import {ESRI_API, MAP} from '../../config';
import {VOKAR, WEBATLAS_GRAU} from '../../services';
import {VBasemap, VGraphic, VMap, VMapImageLayer, VMapView, VWmsLayer, VWmtsLayer, waitUntil} from 'vuesri';
import {loadModules, setDefaultOptions} from 'esri-loader';
import {filterTree} from "../../common/lang";
import _cloneDeep from 'lodash/cloneDeep';
import {geojsonToArcGIS} from "../../common/geometry";

setDefaultOptions({ ...ESRI_API });

export default {
  components: {
    VLayoutMap,
    VMapBasemapToggler,
    VMapCoordinateViewer,
    VMapCopyright,
    VMapScaleChanger,
    VMapToolbar,
    VMapToolCollapseContent,
    VMapToolToggleVisibility,
    VMapToolZoomToExtent,
    VVerifyStichprobe,
    VGraphic,
    VMap,
    VMapImageLayer,
    VMapView,
    VBasemap,
    VWmsLayer,
    VWmtsLayer
  },
  props: {
    user: {},
    stichprobeId: {}
  },
  $esriModules: undefined,
  data () {
    return {
      basemap: {
        id: 'map',
        title: 'Karte',
        service: WEBATLAS_GRAU
      },
      constraints: {
        snapToZoom: true,
        lods: MAP.lods
      },
      drawer: true,
      extent: MAP.initExtent,
      initExtent: MAP.initExtent,
      position: null,
      scale: null,
      services: {
        VOKAR
      },
      stichprobe: {
        vorschrift: {}
      },
      sidebar: !this.$vuetify.breakpoint.smAndDown,
      ui: {
        components: ['zoom']
      },
      viewReady: null,
      visibleBasemap: null,
      visibleGraphic: true,
      visibleLayers: [],
    };
  },
  computed: {
    graphic () {
      return this.stichprobe.geometry
        ? this.createGraphic(this.stichprobe.geometry)
        : null;
    },
    tocItems () {
      const byTitle = this.filterServiceByTitle(
        this.filterServiceByTitle(
          this.services.VOKAR.sublayers,
          'Fachdaten'
        ),
        'vokar_next'
      );

      const role = this.stichprobe.vorschrift.role;
      return role
        ? this.filterServiceByRole(byTitle[0].sublayers, role)[0].sublayers
        : [];
    }
  },
  watch: {
    async graphic (graphic) {
      await waitUntil(30 * 1000, () => this.viewReady);
      this.extent = await this.getGraphicExtent(graphic);
      this.initExtent = _cloneDeep(this.extent);
    },
    tocItems (tocItems) {
      this.visibleLayers = tocItems.map(item => item.id);
    }
  },
  async created () {
    this.$esriModules = await loadModules([
      'esri/geometry/support/jsonUtils'
    ]);
  },
  async mounted () {
    this.stichprobe = await this.fetchStichprobeById(this.stichprobeId);
    this.visibleBasemap = this.basemap
  },
  methods: {
    async fetchStichprobeById (id) {
      try {
        const { data } = await StichprobeRepository.findById(id);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },
    async getGraphicExtent (graphic = null) {
      await waitUntil(30 * 1000, () => this.$esriModules);
      const [jsonUtils] = this.$esriModules;
      let extent = null;
      if (graphic) {
        extent = jsonUtils.fromJSON(graphic.geometry).extent;
        extent = extent.expand(1.25).toJSON();
      }
      return extent;
    },
    filterServiceByTitle(service, title) {
      return filterTree(
        service,
        [title],
        'title',
        'sublayers'
      )
    },
    filterServiceByRole(service, role) {
      return filterTree(
        service,
        [role],
        '_role',
        'sublayers'
      )
    },
    createGraphic(geometry) {
      return {
        geometry: geojsonToArcGIS(geometry),
        symbol: {
          type: 'simple-fill',
          color: [0, 0, 0, 0],
          outline: { type: 'simple-line', color: [0, 0, 0], width: 2 }
        }
      };
    }
  }
};
</script>

<style scoped>
>>> .v-map-layout__toolbar-bottom-left .v-toolbar__content {
  padding-right: 4px;
}
</style>
