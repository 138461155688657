<template>
  <VMapTool
    v-bind="$attrs"
    :icon="icon"
    @click="$emit('input', !value)"
  />
</template>

<script>
import VMapTool from '../VMapTool';

export default {
  components: {
    VMapTool
  },
  props: {
    value: {}
  },
  computed: {
    icon () {
      return this.value ? 'mdi-eye-off' : 'mdi-eye';
    }
  }
};
</script>
